<template>
  <div>
    <el-form class="search_unit" ref="searchUnit" :model="searchForm" label-position="top">
      <div class="row no_border">
        <el-form-item :label="$t('Label.Declaration No')" prop="declarationNo">
          <el-input type="text" v-model="searchForm.declarationNo" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Permit Date')" prop="dateOfPermit">
          <el-date-picker
            v-model="searchForm.dateOfPermit"
            type="daterange"
            range-separator="to"
            format="yyyy-MM-dd"
            :default-value="defaultDate"
            @change.native="onDateRangeChange"
            start-placeholder="Start date"
            end-placeholder="End date">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Label.Invoice No')" prop="invoiceNo">
          <el-input type="text" v-model="searchForm.invoiceNo" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Shipper')" prop="shipperName">
          <el-input type="text" v-model="searchForm.shipperName" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Consignee')" prop="consigneeName">
          <el-input type="text" v-model="searchForm.consigneeName" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
      </div>
      <div class="row no_border">
        <el-form-item :label="$t('Label.Main Goods')" prop="goodsName">
          <el-input type="text" v-model="searchForm.goodsName" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Quantity')" prop="totalQuantity">
          <el-input-number :controls="false" v-model="searchForm.totalQuantity" placeholder="Enter keyword" style="width: 160px" :min="-maxNum" :max="maxNum" />
        </el-form-item>
        <el-form-item :label="$t('Label.Invoice Amount')" prop="totalAmount">
          <div class="el-date-editor el-range-editor el-input__inner el-date-editor--daterange amount_range" style="width: 160px;padding-left:4px">
            <el-input-number :controls="false" placeholder="Min" name="" class="el-range-input" v-model="searchForm.invoiceAmountFrom" :min="-maxNum" :max="maxNum" /><span class="el-range-separator">to</span><el-input-number :controls="false" placeholder="Max" name="" class="el-range-input" v-model="searchForm.invoiceAmountTo" :min="-maxNum" :max="maxNum" />
          </div>
        </el-form-item>
        <el-form-item :label="$t('Label.Vessel Name')" prop="vesselName">
          <el-input type="text" v-model="searchForm.vesselName" placeholder="Enter keyword" maxlength="300"></el-input>
        </el-form-item>
        <el-form-item :label="$t('Label.Upload Date')" prop="receiveDate">
          <el-date-picker
            v-model="searchForm.receiveDate"
            type="daterange"
            range-separator="to"
            format="yyyy-MM-dd"
            :default-value="defaultDate"
            @change.native="onDateRangeChange"
            start-placeholder="Start date"
            end-placeholder="End date">
          </el-date-picker>
        </el-form-item>
      </div>
      <div class="row no_border drop">
        <div>
          <tw-button
            type="secondary"
            size="medium"
            icon="search"
            @click="search"
          >
            Search
          </tw-button>
          <tw-button
            type="default"
            size="medium"
            @click="clear"
            style="margin: 8px 16px 16px;"
          >
            Clear
          </tw-button>
        </div>
      </div>
    </el-form>

    <div style="min-height:388px">
      <tw-table-system
        :height="300"
        :schemas="schemas"
        :items="items"
        :pageSize="limit"
        :totalCount="totalCount"
        :offset="offset"
        :serverPagination="true"
        @row-click="register"
        @paging="onPaging"
        mode="POOL"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { PROCESS_TYPE, PERMIT_LINK_FLG } from 'lib-tw-common';
import TwButton from '@/components/atoms/TwButton';
import TwTableSystem from '@/components/organisms/TwTableSystem';
import { $api } from '@/store/ApiClient';
import mixinEntityList from '@/utils/mixinEntityList.js';
import { numberToString, clearSearchForm } from '@/utils/searchUtil.js';
import exSchemas from '@/dictionaries/ListExportCustomsPermitListSchema.json';
import imSchemas from '@/dictionaries/ListImportCustomsPermitListSchema.json';
import dayjs from 'dayjs';

export default {
  name: 'TwAddPermit',
  mixins: [mixinEntityList],
  components: {
    TwButton,
    TwTableSystem
  },
  props: {
    permit: Object,
    processType: String
  },
  data() {
    return {
      items: null,
      schemas: this.createSchemas(),
      limit: 20,
      searchForm: {
        declarationNo: '',
        dateOfPermit: '',
        invoiceNo: '',
        shipperName: '',
        consigneeName: '',
        goodsName: '',
        totalQuantity: undefined,
        invoiceAmountFrom: undefined,
        invoiceAmountTo: undefined,
        vesselName: '',
        receiveDate: '',
        includeDeletedData: false,
        linkingFlag: String(PERMIT_LINK_FLG.OFF)
      },
      offset: 0,
      sort: null,
      count: 0,
      totalCount: 0,
      pageCount: 0,
      maxNum: 10 ** 16 // 300桁
    };
  },
  created() {
    const queries = this.processType === PROCESS_TYPE.ECPERMIT ? this.$store.getters.getQueries('EX_CUSTOM_PERMIT_SELECT') : this.$store.getters.getQueries('IM_CUSTOM_PERMIT_SELECT');
    if (queries) {
      this.searchForm = queries;
    } else {
      // 検索初期条件：Receive Data直近1ヶ月昇順
      const today = dayjs()
        .hour(0)
        .minute(0)
        .second(0);
      this.searchForm.receiveDate = [today.subtract(1, 'month').format(), today.format()];
    }
    this.fetch();
  },
  methods: {
    // processTypeによってスキーマを出し分けます
    createSchemas() {
      const schemas = this.processType === PROCESS_TYPE.ECPERMIT ? exSchemas : imSchemas;
      // tradingFlowIdとprocessNameは除外
      return _.reject(schemas, o => {
        return o.key === 'tradingFlowId' || o.key === 'processName';
      });
    },
    // POSTパラメータを生成します
    transformQueries() {
      const f = this.searchForm;
      return {
        declarationNo: f.declarationNo,
        dateOfPermitFrom: f.dateOfPermit && f.dateOfPermit.length ? dayjs(this.getStartDate(f.dateOfPermit)).format('YYYYMMDD') : null,
        dateOfPermitTo: f.dateOfPermit && f.dateOfPermit.length ? dayjs(this.getEndDate(f.dateOfPermit)).format('YYYYMMDD') : null,
        invoiceNo: f.invoiceNo,
        shipperName: f.shipperName,
        consigneeName: f.consigneeName,
        goodsName: f.goodsName,
        cargoQuantity: numberToString(f.totalQuantity),
        invoiceAmountFrom: numberToString(f.invoiceAmountFrom),
        invoiceAmountTo: numberToString(f.invoiceAmountTo),
        vesselName: f.vesselName,
        createDateFrom: this.getStartDate(f.receiveDate),
        createDateTo: this.getEndDate(f.receiveDate),
        linkingFlag: String(PERMIT_LINK_FLG.OFF),
        includeDeletedData: false,
        limit: this.limit,
        offset: this.offset,
        sortType: this.sort ? this.sort.sortType : null,
        target: this.sort ? this.sort.target : null
      };
    },
    fetch() {
      // bff_ncp_2 輸出許可書プール一覧照会BFF
      // bff_ncp_6 輸入許可書プール一覧照会BFF
      const apiCode = this.processType === PROCESS_TYPE.ECPERMIT ? 'get_/v1/nc-pool-datas/export/search' : 'get_/v1/nc-pool-datas/import/search';
      const params = {
        lslConfig: {
          serviceCode: 'tw-pooldata-bff-api',
          apiCode: apiCode,
          query: this.transformQueries()
        }
      };

      $api
        .request(params)
        .then(res => {
          this.items = res.naccspools || [];
          // 選択中のデータには選択済みフラグを追加する
          if (this.permit && Object.keys(this.permit).length) {
            this.items = _.map(this.items, item => {
              const declarationNo = _.get(this, 'permit.declarationNo', '');
              const declarationDate = String(_.get(this, 'permit.declarationDate', ''));
              if (declarationNo === item.declarationNo && declarationDate === item.declarationDate) {
                item.poolSelected = true;
              }
              return item;
            });
          }
          this.totalCount = res.totalCount;
        })
        .catch(err => {
          this.items = [];
          if (err.statusCode !== 403) this.$store.dispatch('SHOW_ALERT', err.message);
        });
    },
    search() {
      this.offset = 0;
      this.items = null;
      const key = this.processType === PROCESS_TYPE.ECPERMIT ? 'EX_CUSTOM_PERMIT_SELECT' : 'IM_CUSTOM_PERMIT_SELECT';
      this.$store.commit('SET_QUERIES', { key: key, queries: _.cloneDeep(this.searchForm) });
      this.fetch();
    },
    clear() {
      this.searchForm = clearSearchForm(this.searchForm);
      const key = this.processType === PROCESS_TYPE.ECPERMIT ? 'EX_CUSTOM_PERMIT_SELECT' : 'IM_CUSTOM_PERMIT_SELECT';
      this.$store.commit('SET_QUERIES', { key: key, queries: _.cloneDeep(this.searchForm) });
    },
    onPaging({ target, sortType, currentPage }) {
      if (target && sortType) {
        this.sort = {
          target: target,
          sortType: sortType
        };
        if (this.sort.target === 'updater') this.sort.target = 'updaterUserName';
      } else {
        this.sort = null;
      }

      this.offset = currentPage * this.limit - this.limit;
      this.fetch();
    },
    register(row) {
      this.$emit('add-permit', row);
    }
  }
};
</script>

<style lang="scss">
.el-dialog__wrapper[aria-label='Select Permit'] .el-dialog {
  .el-dialog__body {
    padding-top: 12px;
  }

  h2 {
    margin: 0 0 24px;
    padding: 12px 0;
    border-bottom: 1px solid $color_gray_300;
    color: $color_gray_800;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  // .el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange {
  //   width: 220px;
  // }
}

.tw_button__icon.icon_search {
  padding-left: 12px;
  padding-right: 20px;
}
</style>
